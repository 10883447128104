<template>
  <v-form ref="form" lazy-validation>
    <v-row class="text-center mb-3">
      <v-col>
        <span class="font-weight-bold white--text">{{ $t('home.title') }}</span>
      </v-col>
    </v-row>

    <v-card>
      <v-container fluid>
        <v-row class="mt-3">
          <v-col>
            <span class="font-weight-bold text-pre-wrap">{{ $t('redeem.tip2') }}<span class="red--text">*</span></span>
            <v-text-field :label="$t('redeem.code')" v-model="code" :loading="loading" @keydown.enter.prevent="submit"
                          :rules="rules.code"/>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <span class="text-pre-wrap text-caption font-weight-bold">{{ $t('redeem.tip1') }}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" @click="submit" large color="primary" class="mb-3">{{
            $t('next')
          }}
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "RedeemPage",
  computed: {
    code: {
      get() {
        return this.$store.state.review.code
      },
      set(val) {
        this.$store.commit('review/CODE', val)
      }
    }
  },
  data() {
    return {
      loading: false,
      rules: {
        code: [
          v => !!v || this.$t("redeem.codeAlert")
        ]
      }
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.getGift().then(
            response => {
              this.loading = false
              if (response.data) {
                this.$store.commit('review/GIFT', response.data.id)
                this.$router.replace('/warranty/it')
              } else {
                alert(this.$t("redeem.codeAlert"));
              }
            }, () => {
              this.loading = false;
              alert(this.$t("redeem.codeAlert"));
            }
        )
      }
    },
    initTestData() {
      if (process.env.NODE_ENV === "development") {
        this.code = "6615";
      }
    },
    ...mapActions({
      getGift: 'review/getGift'
    })
  }, mounted() {
    this.initTestData()
  }
}
</script>

<style scoped>

</style>