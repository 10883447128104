<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-container fluid>
        <v-row class="mt-3 text-center">
          <v-col>
            <span class="text-h5 font-weight-bold">{{ $t('review.title') }}</span>
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-5"/>
        <v-row>
          <v-col>
            <p>{{ $t('review.reviewTip1') }}</p>
            <p v-html="$t('review.reviewStep1')"></p>
            <p v-html="$t('review.reviewStep2')"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12">
            <v-img src="https://s3.us-west-1.amazonaws.com/item.review/image/it/step0.jpeg" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>{{ $t('review.reviewStep3') }}</p>
            <p>{{ $t('review.reviewStep4') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" cols="12">
            <p class=" font-weight-bold">{{ $t('review.reviewStep41') }}</p>
            <v-img src="https://s3.us-west-1.amazonaws.com/item.review/image/it/step1.jpeg" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col md="4" cols="12">
            <p class=" font-weight-bold">{{ $t('review.reviewStep42') }}</p>
            <v-img src="https://s3.us-west-1.amazonaws.com/item.review/image/it/step2.jpeg" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col md="4" cols="12">
            <v-icon color="error" class="mr-1">fas fa-exclamation-triangle</v-icon>
            Solo le foto dei prodotti non sono conformi
            <v-img src="https://s3.us-west-1.amazonaws.com/item.review/image/it/step3.jpeg" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>{{ $t('review.reviewTip2') }}</p>
            <p>{{ $t('review.reviewTip3') }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input show-size accept="image/png,image/jpg,image/jpeg"
                          :label="$t('review.imageTip')" v-model="image"
                          :rules="rules.image" :disabled="loading"/>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" @click="previous" large color="warning" class="mb-3 mr-3">{{
            $t('previous')
          }}
        </v-btn>
        <v-btn :loading="loading" @click="openDialog" large color="primary" class="mb-3">{{
            $t('submit')
          }}
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" width="unset" persistent>
      <v-card max-width="100vh">
        <v-card-title class="text-h5">
          {{ $t("review.dialog.title") }}
        </v-card-title>
        <v-card-text>
          <v-img :src="getImageUrl" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="warning darken-1" text @click="dialog = false" :loading="loading">
            {{ $t("review.dialog.cancel") }}
          </v-btn>
          <v-btn color="primary darken-1" text @click="submit" :loading="loading">
            {{ $t("review.dialog.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ReviewPage",
  computed: {
    image: {
      get() {
        return this.$store.state.review.image
      },
      set(val) {
        this.$store.commit('review/IMAGE', val)
      }
    },
    realImageUrl() {
      return this.image ? URL.createObjectURL(this.image) : this.getImageUrl
    },
    getImageUrl() {
      return this.$vuetify.breakpoint.mdAndUp ? require("@/assets/review_example_it1.jpeg") : require("@/assets/review_example_it2.jpeg")
    },
    ...mapState({
      customer: state => state.review.customer,
      market: state => state.review.marketId
    })
  },
  data() {
    return {
      loading: false,
      dialog: false,
      rules: {
        image: [
          v => !!v || this.$t("review.imageAlert")
        ],
      }
    }
  },
  methods: {
    previous() {
      this.$router.replace('/survey')
    },
    openDialog() {
      if (this.$refs.form.validate()) {
        this.dialog = true
      }
    },
    submit() {
      this.loading = true
      this.dialog = false
      this.submitReview().then(() => {
        this.loading = false
        this.$router.replace("/finished")
      }, () => {
        this.loading = false
      })
    },
    ...mapActions({
      submitReview: 'review/submit'
    })
  },
  mounted() {
    if (!this.customer) {
      this.$router.replace("/")
    }
  }
}
</script>

<style scoped>

</style>